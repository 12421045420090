import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  patientRecords: [],
  toatalPages: 0,
  patientById: [],
};

export const PatientSlice = createSlice({
  name: "patients",
  initialState,
  reducers: {
    getPatientRecords: (state, action) => {
      state.patientRecords = action.payload.data;
      state.toatalPages = action.payload.totalPages;
    },
    getPatientById: (state, action) => {
      state.patientById = action.payload;
    },
    resetPatientById: (state, action) => {
      state.patientById = null;
    },
  },
});
export const { getPatientRecords, getPatientById, resetPatientById } =
  PatientSlice.actions;

export default PatientSlice.reducer;

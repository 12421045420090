import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  inventoryData: [],
  totalPages: 0,
};

export const InventorySlice = createSlice({
  name: "inventoryList",
  initialState,
  reducers: {
    getInventoryList: (state, action) => {
      state.inventoryData = action.payload.data;
      state.totalPages = action.payload.totalPages;
      state.searchRequest = action.payload.searchRequest;
    },
    getStockTypeList: (state, action) => {
      state.stockTypeList = action.payload;
    },
  },
});
export const { getInventoryList, getStockTypeList } = InventorySlice.actions;

export default InventorySlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  treatmentList: [],
  treatmentDetails: {},
  priceCatalogList: [],
  treatmentTagCategories: [],
};

export const TreatmentSlice = createSlice({
  name: "treatments",
  initialState,
  reducers: {
    setTreatmentRecords: (state, action) => {
      state.treatmentList = action.payload;
    },
    setTreatmentDetails: (state, action) => {
      state.treatmentDetails = action.payload;
    },
    resetTreatmentDetails: (state) => {
      state.treatmentDetails = {};
    },
    setPriceCatalogRecords: (state, action) => {
      state.priceCatalogList = action.payload;
    },
    setTreatmentTagRecords: (state, action) => {
      state.treatmentTagCategories = action.payload;
    },
  },
});

export const {
  setTreatmentRecords,
  setTreatmentDetails,
  resetTreatmentDetails,
  setPriceCatalogRecords,
  setTreatmentTagRecords,
} = TreatmentSlice.actions;

export default TreatmentSlice.reducer;

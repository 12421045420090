import{createSlice}from "@reduxjs/toolkit";
const initialState = {
    departmentsRecords:[],
};
 export const departmentsSlice = createSlice({
    name: "departmentsList",
    initialState,
    reducers: {
        SetAllDepartmentsRecords: (state, action) => {
            state.departmentsRecords = action.payload;
        },
   },
});
export const{SetAllDepartmentsRecords} = departmentsSlice.actions;
 export default departmentsSlice.reducer;

const ScreenRouteEnum = {
  Admin: {
    id: 1,
    key: "1",
    name: "admin",
    //path: `${selectedBranchId}/admin`,
  },
  Appointments: {
    id: 2,
    key: "2",
    name: "appointments",
    //path: `${selectedBranchId}/appointments`,
  },
  Patients: {
    id: 3,
    key: "3",
    name: "patients",
    //path: `${selectedBranchId}/patients`,
  },
  Schedule: {
    id: 4,
    key: "4",
    name: "schedule",
    //path: `${selectedBranchId}/schedule`,
  },
  Treatments: {
    id: 5,
    key: "5",
    name: "treatments",
    //path: `${selectedBranchId}/treatments`,
  },
  Inventory: {
    id: 6,
    key: "6",
    name: "inventory",
    //path: `${selectedBranchId}/inventory`,
  },
  Billing: {
    id: 7,
    key: "7",
    name: "billing",
    //path: `${selectedBranchId}/billing`,
  },
  Revenue: {
    id: 8,
    key: "8",
    name: "revenue",
    //path: `${selectedBranchId}/revenue`,
  },
};

export default ScreenRouteEnum;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  appointmentRecords: [],
  currentPage: 1,
  toatalPages: 0,
  patientAppointments: null,
  appointmentStatusCount: null,
  selectedPatient: {},
  recentAppointmentList: [],
  appointmentRecordsList: [],
  updateNotes: [],
  drugsList: [],
  filePath: "",
  pdfFilePath: "",
  labTestList: [],
  imagePath: "",
};

export const AppointmentsSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {
    getAppointmentRecords: (state, action) => {
      state.appointmentRecords = action.payload.data;
      state.toatalPages = action.payload.totalPages;
    },
    setPatientAppointments: (state, action) => {
      state.patientAppointments = action.payload;
    },
    resetPatientAppointments: (state) => {
      state.patientAppointments = null;
    },
    getAppointmentsStatusCount: (state, action) => {
      state.appointmentStatusCount = action.payload;
    },
    setSelectedPatient: (state, action) => {
      state.selectedPatient = action.payload;
    },
    getRecentAppointmentsList: (state, action) => {
      state.recentAppointmentList = action.payload.data;
      state.toatalPages = action.payload.totalPages;
    },
    setAppointmentRecordsList: (state, action) => {
      state.appointmentRecordsList = state.appointmentRecordsList.filter(
        (record) => record.appointmentId !== action.payload.appointmentId
      );

      state.appointmentRecordsList.push(action.payload);
    },
    resetAppointmentRecordsList: (state) => {
      state.appointmentRecordsList = [];
    },
    setDrugsList: (state, action) => {
      state.drugsList = action.payload;
    },
    setFileByFilePath: (state, action) => {
      state.filePath = action.payload;
    },
    setPdfFileByFilePath: (state, action) => {
      state.pdfFilePath = action.payload;
    },
    setLabTestList: (state, action) => {
      state.labTestList = action.payload;
    },
    setImageByImagePath: (state, action) => {
      state.imagePath = action.payload;
    },
  },
});
export const {
  getAppointmentRecords,
  setPatientAppointments,
  resetPatientAppointments,
  getAppointmentsStatusCount,
  setSelectedPatient,
  getRecentAppointmentsList,
  setAppointmentRecordsList,
  resetAppointmentRecordsList,
  setDrugsList,
  setFileByFilePath,
  setPdfFileByFilePath,
  setLabTestList,
  setImageByImagePath,
} = AppointmentsSlice.actions;

export default AppointmentsSlice.reducer;

import authentication from "features/OnBoarding/authenticationSlice";
import schedule from "features/Schedule/ScheduleSlice";

import GlobalSpinner from "features/CommonComponents/GlobalSpinner/GlobalSpinnerSlice";
import RoleManagement from "features/Admin/Roles/RolesSlice";
import userlist from "features/Admin/Users/UsersSlice";
import appointments from "features/Appointments/AppointmentsSlice";
import patients from "features/Patients/PatientsSlice";
import departments from "features/Admin/Department/DepartmentSlice";
import treatments from "features/Treatments/TreatmentsSlice";
import inventoryList from "features/Inventory/InventorySlice";

//Include all the reducer to combine and provide to configure store.

const rootReducer = {
  authentication,
  schedule,
  GlobalSpinner,
  RoleManagement,
  userlist,
  appointments,
  patients,
  departments,
  treatments,
  inventoryList,
};
export default rootReducer;

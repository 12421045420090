import { lazy, Suspense, useEffect, useState } from "react";
import {
  Route,
  BrowserRouter,
  Routes,
  Link,
  useLocation,
  Navigate,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./App.scss";
import { loginSessionAvailable } from "./features/OnBoarding/authenticationSlice";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
const ProtectedRoutes = lazy(() => import("./routes/ProtectedRoutes"));
const PrivateRoutes = lazy(() => import("./routes/PrivateRoutes"));
const LoginPage = lazy(() =>
  import("./features/OnBoarding/Components/LoginPage")
);
const ForgotPassword = lazy(() =>
  import("./features/OnBoarding/Components/ForgotPassword")
);
const SetPassword = lazy(() =>
  import("./features/OnBoarding/Components/SetPassword")
);
const Schedule = lazy(() => import("./features/Schedule/Schedule"));
const MonthView = lazy(() =>
  import("./features/Schedule/components/ScheduleMonthView")
);
const Appointments = lazy(() => import("./features/Appointments/Appointments"));
const AddPatient = lazy(() =>
  import("./features/Patients/components/AddPatient")
);
const Treatments = lazy(() => import("./features/Treatments/Treatments"));
// const Pricecatalog = lazy(() => import("./features/Treatments/PriceCatalog"));
const Treatmentdetails = lazy(() =>
  import("./features/Treatments/Components/TreatmentDetails")
);
const AddTreatment = lazy(() =>
  import("./features/Treatments/Components/AddTreatment")
);
const Inventory = lazy(() => import("./features/Inventory/Inventory"));
const AppointmentListView = lazy(() =>
  import("./features/Schedule/components/ScheduleListView")
);
const Sendstock = lazy(() =>
  import("./features/Inventory/Components/SendStock")
);
const Billing = lazy(() => import("./features/Billing/Billing"));
const AddBilling = lazy(() => import("./features/Billing/AddBilling"));
const Payment = lazy(() => import("./features/Billing/PaymentOptions"));
const Paymentsuccess = lazy(() => import("./features/Billing/PaymentSuccess"));
const Admin = lazy(() => import("./features/Admin/Admin"));
const Revenue = lazy(() => import("./features/Billing/AddBilling"));
const AddUser = lazy(() => import("./features/Admin/Users/component/AddUser"));
const EditUser = lazy(() => import("./features/Admin/Users/component/AddUser"));
const Patients = lazy(() => import("./features/Patients/Patients"));
const EditPatient = lazy(() =>
  import("./features/Patients/components/AddPatient")
);
function App() {
  const dispatch = useDispatch();

  //Getting isAuthenticated store value from Authentication slice.
  //Testing Line
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const isAuthenticatedState = useSelector(
    (state) => state.authentication.isAuthenticated
  );
  const defaultBranchId = parseInt(localStorage.getItem("selectedBranch"));
  const userDefaultBranch = parseInt(localStorage.getItem("defaultBranch"));
  const defaultScreen = localStorage.getItem("defaultScreen");
  var isAuthenticateStorage = JSON.parse(
    localStorage.getItem("isAuthenticated") || false
  );
  var token_bearer = localStorage.getItem("token_bearer");
  const lastVisitedPath = localStorage.getItem("lastVisitedPath");
  useEffect(() => {
    const path = window.location.pathname;
    const pathSegments = path.split("/").filter(Boolean);
    if (pathSegments.length > 0) {
      const branchIdFromPath = parseInt(pathSegments[0]);
      if (!isNaN(branchIdFromPath) && !pathSegments.includes("login")) {
        localStorage.setItem("selectedBranch", branchIdFromPath);
      }
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (!isAuthenticatedState) {
      setIsAuthenticated(isAuthenticateStorage);
      if (isAuthenticateStorage && token_bearer) {
        dispatch(loginSessionAvailable());
      } else {
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(isAuthenticatedState);
    }
  }, [isAuthenticatedState, dispatch]);

  return (
    <>
      {isAuthenticated != null && (
        <BrowserRouter>
          <Routes>
            <Route
              path="/forgotpassword"
              element={
                <Suspense
                  fallback={
                    <div className="fallback-loader2">
                      <Spin spinning size="large" />
                    </div>
                  }
                >
                  <ForgotPassword />
                </Suspense>
              }
            />
            <Route
              path="/setpassword/:token"
              element={
                <Suspense
                  fallback={
                    <div className="fallback-loader2">
                      <Spin spinning size="large" />
                    </div>
                  }
                >
                  <SetPassword />
                </Suspense>
              }
            />
            {/* Below will be public pages */}
            <Route
              element={<PublicRoutes isAuthenticated={isAuthenticateStorage} />}
            >
              <Route
                path="/login"
                element={
                  <Suspense
                    fallback={
                      <div className="fallback-loader2">
                        <Spin spinning size="large" />
                      </div>
                    }
                  >
                    <LoginPage />
                  </Suspense>
                }
              />
            </Route>

            {/* Below will work only when user loggedin to the system */}
            <Route
              element={
                <PrivateRoutes isAuthenticated={isAuthenticateStorage} />
              }
            >
              <Route
                path="/"
                element={
                  lastVisitedPath ? (
                    lastVisitedPath == "/" ? (
                      <Navigate to={`/${userDefaultBranch}/${defaultScreen}`} />
                    ) : (
                      <Navigate to={lastVisitedPath} />
                    )
                  ) : (
                    <Navigate to={`/${defaultBranchId}/${defaultScreen}`} />
                  )
                }
              />
              <Route path="/:branchId/schedule" element={<Schedule />} />
              <Route path="/:branchId/monthview" element={<MonthView />} />
              <Route
                path="/:branchId/appointments"
                element={<Appointments />}
              />
              <Route path="/:branchId/addpatient" element={<AddPatient />} />
              <Route path="/:branchId/treatments" element={<Treatments />} />
              {/* <Route
                path="/:branchId/pricecatalog"
                element={<Pricecatalog />}
              /> */}
              <Route
                path="/:branchId/treatments/:treatmentId"
                element={<Treatmentdetails />}
              />
              <Route
                path="/:branchId/treatments/add"
                element={<AddTreatment />}
              />
              <Route
                path="/:branchId/treatments/update/:treatmentId"
                element={<AddTreatment />}
              />
              <Route path="/:branchId/inventory" element={<Inventory />} />
              <Route
                path="/:branchId/appointmentlistview"
                element={<AppointmentListView />}
              />
              <Route path="/:branchId/sendstock" element={<Sendstock />} />
              <Route path="/:branchId/billing" element={<Billing />} />
              <Route path="/:branchId/addbilling" element={<AddBilling />} />
              <Route path="/:branchId/payment" element={<Payment />} />
              <Route
                path="/:branchId/paymentsuccess"
                element={<Paymentsuccess />}
              />
              <Route path="/:branchId/admin" element={<Admin />} />
              <Route path="/:branchId/revenue" element={<Revenue />} />
              <Route path="/:branchId/addUser" element={<AddUser />} />
              <Route
                path="/:branchId/editUser/:userId"
                element={<EditUser />}
              />
              <Route
                path="/:branchId/editPatient/:userId"
                element={<EditPatient />}
              />
              <Route path="/:branchId/patients" element={<Patients />} />
            </Route>
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
}

function PublicRoutes({ isAuthenticated }) {
  const location = useLocation();
  return !isAuthenticated ? (
    <Suspense
      fallback={
        <div className="fallback-loader2">
          <Spin spinning size="large" />
        </div>
      }
    >
      <LoginPage />
    </Suspense>
  ) : (
    <Navigate
      to={{
        pathname: "/",
        state: { from: location },
      }}
    />
  );
}

export default App;
